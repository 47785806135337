$bestcardprocessingrates-why-us-brief: #747474;

$bestcardprocessingrates-default-link-color: #5c5c5c;
$bestcardprocessingrates-default-checkbox-selected-bgcolor: #ecf5f4;

$bestcardprocessingrates-default-button-bgcolor: #384c81;
$bestcardprocessingrates-default-button-hover-bgcolor: #2d427d;
$bestcardprocessingrates-default-button-text-color: #000;
$bestcardprocessingrates-default-button-border-color: #2f4700;

$bestcardprocessingrates-default-radio-button-bgcolor: #e9efff;
$bestcardprocessingrates-default-radio-button-text-color: #000;
$bestcardprocessingrates-default-radio-hover-bgcolor: $bestcardprocessingrates-default-button-hover-bgcolor;
$bestcardprocessingrates-default-radio-hover-text-color: #fff;
$bestcardprocessingrates-default-button-text-color: #fff;

$bestcardprocessingrates-default-hover-button-bgcolor: #034749;
$bestcardprocessingrates-default-hover-button-border-color: #000000;
$bestcardprocessingrates-default-hover-button-text-color: #fff;

$slogan: #444b53;
$bg1: #005c58;
$textColor: #fff;
$bestcardprocessingrates-progress-color: #aaa;

$bestcardprocessingrates-block-bg: #384c81;
$bestcardprocessingrates-block-bg2: #2a4384;
$bestcardprocessingrates-block-color: #fff;

:export {
    slogan: $slogan;
    primaryColor: $bestcardprocessingrates-default-button-bgcolor;
    progressColor: $bestcardprocessingrates-progress-color;
    whyUsBriefColor: $bestcardprocessingrates-why-us-brief;
    linkColorDefault: $bestcardprocessingrates-default-link-color;
    buttonBgColorDefault: $bestcardprocessingrates-default-button-bgcolor;
    whyUsBackgroundColor:$bestcardprocessingrates-block-bg;
    whyUsColor:$bestcardprocessingrates-block-color;
}


            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.mainWrapper {
    background-size: cover;
    // background-image: url("/assets/images/shutterstock_1511135942.jpg");
    background-image: url("/assets/images/bg2.jpg");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-color: #e5e5e5;
    @media screen and (max-width: 1024px) {
        background-image: url("/assets/images/mobile.jpg");
        background-size: contain;
    }
}

.main-banner {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    // height: calc(100vh - 58px);
    min-height: calc(100vh - 52px);
    // @include lg {
    //     // font-size: 2.8rem !important;
    //     // line-height: 1.3;
    //     justify-content: space-between;
    //     align-items: flex-start;
    // }
    // @media screen and (max-width: 1200px) {
    //     align-items: center;
    // }
    // @media screen and (max-width: 900px) {
    //     flex-direction: column;
    // }

    .form {
        z-index: 5;
        width: 100%;
        padding: 30px 24px;
        max-width: 700px;
        border-radius: 10px;
        min-height: 500px;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        position: relative;
        border-radius: 15px;
        box-shadow: 0px 0px 10px 1px #ccc;
        @media screen and (max-width: 900px) {
            margin-bottom: 0;
        }
        .steps-inner {
            flex-grow: 1;
            padding-bottom: 20px;
        }
        .step-block:nth-child(1) {
            .step-title {
                text-align: center;
                margin-bottom: 50px;
                @media screen and (max-width: 1023px) {
                    margin-bottom: 30px;
                    font-size: 21px !important;
                }
            }
            .step-description {
                font-size: 18px;
                font-weight: 700;
                text-align: center;
                @media screen and (max-width: 1023px) {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
            .form-field {
                > label {
                    font-size: 18px;
                    font-weight: 700;
                    text-align: center;
                    margin-bottom: 2rem;

                    @media screen and (max-width: 1023px) {
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
        }
        h1 {
            &.step-title {
                font-size: 2.6rem;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 2.5rem !important;
                    line-height: 1.3;
                }
            }
        }
        .step-title {
            text-align: left;
            font-size: 20px !important;
            font-weight: 700 !important;
            margin-bottom: 10px;
            color: black;
            @include lg {
                font-size: 30px !important;
            }
        }
        .label-as-step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 700 !important;
            margin-top: -20px;
            margin-bottom: 25px !important;
            @include lg {
                font-size: 30px !important;
            }
        }
        .step-description {
            text-align: left;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 18px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            transition: all 0.2s ease-in-out;
            border-radius: 40px !important;
            background-color: $bestcardprocessingrates-default-button-bgcolor !important;
            color: $bestcardprocessingrates-default-button-text-color !important;
            @apply shadow-lg;

            svg {
                path {
                    fill: black;
                }
            }
            @include lg {
                // max-width: 450px !important;
            }
            &:hover {
                @apply shadow;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            // padding-left: 10px;
            // padding-right: 10px;
            // label {
            //     margin-left: 7px !important;
            // }
            @include lg {
                // max-width: 450px;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            margin-top: 10px;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 700;
                        background-color: $bestcardprocessingrates-default-button-hover-bgcolor;
                        border-color: $bestcardprocessingrates-default-button-border-color;
                        color: $bestcardprocessingrates-default-radio-hover-text-color;
                    }
                }
                label {
                    border-radius: 40px;
                    border: 2px solid
                        $bestcardprocessingrates-default-button-border-color;
                    color: $bestcardprocessingrates-default-radio-button-text-color;
                    background-color: $bestcardprocessingrates-default-radio-button-bgcolor;
                    font-size: 16px;
                    min-height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    span {
                        font-size: 16px !important;
                    }
                    @include md {
                        min-height: 60px;
                    }
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                    &:hover {
                        border-color: $bestcardprocessingrates-default-button-border-color;
                        background-color: $bestcardprocessingrates-default-button-hover-bgcolor;
                        color: $bestcardprocessingrates-default-radio-hover-text-color;
                    }
                }
                @include lg {
                    margin: 0 10px;
                    margin-bottom: 15px;
                    width: 45%;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .checkbox-field {
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 700;
                        background-color: rgba(
                            $bestcardprocessingrates-default-button-bgcolor,
                            0.1
                        );
                    }
                }
                label {
                    border-radius: 3px;
                    border: 1px solid
                        $bestcardprocessingrates-default-button-bgcolor;
                    color: #000;
                    font-size: 16px;
                    display: flex;
                    align-items: center;

                    @include md {
                        min-height: 50px;
                    }
                }
            }
        }
        .field-note {
            margin-left: 7px;
        }
        .field-error-message {
            left: 7px;
        }
        .field {
            &:not(.radio-field) {
                background-color: white;
            }
        }
        input {
            border-radius: 30px;
        }
        .tcpa {
            margin-bottom: 10px;
        }
        @include lg {
            min-width: 300px;
            width: 50%;
            padding: 40px 40px;
            margin-bottom: 200px;
            margin-top: 50px;
        }
    }
    .image {
        z-index: 2;
        & > div {
            position: absolute !important;
            left: 0;
            bottom: 0;
            height: 300px;
            width: 100vw;
            max-width: unset !important;
            left: 50% !important;
            transform: translateX(-50%) !important;

            @include lg {
                height: 100%;
                top: 0 !important;
                left: 0%;
            }
        }

        img {
            min-height: unset !important;
            min-width: unset !important;
            width: auto !important;
            height: 100% !important;
            max-width: unset !important;
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    // &::before {
    //     content: "";
    //     left: 50%;
    //     width: 100vw;
    //     transform: translateX(-50%);
    //     height: 100%;
    //     position: absolute;
    //     background-image: url("/assets/images/main-image-mobile.jpg");
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     background-position: right;
    //     z-index: 2;
    //     @include lg {
    //         background-image: url("/assets/images/main-image-desktop.jpg");
    //     }
    // }
}
.maxWidth546 {
    max-width: 564px;
    @media screen and (max-width: 1200px) {
        max-width: 400px;
    }
}
